






import { PageBase } from "@/core/models/shared";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Home extends PageBase {

}
